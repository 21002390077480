import React from 'react'
import { handleVisit } from '../helpers/handleVisit'

interface BoxWithOverlayProps {
    image : any,
    title: string,
    badges: string[],
    description: React.ReactNode,
    hoverColor?: string,
    projectLink?: string
}

function BoxWithOverlay({image, title, badges, description, hoverColor, projectLink} : BoxWithOverlayProps) {
  return (
    <div onMouseDown={(e) => handleVisit(e, projectLink)} className='' style={{display: "grid", gridTemplateColumns: "auto", cursor: projectLink == undefined ? "default":"pointer"}}>
        <div className='' style={{width:"310px", height:"310px", display: "grid", placeItems:"center"}}>
            <img className='overlay-back' width={310} height={310} src={image} style={{zIndex:"2"}}/>
            <div className='px-3 pt-4 text-start' style={{width:"310px", height:"310px", position:"absolute", zIndex:"1", backgroundColor: hoverColor ?? "#283829"}}>
                {description}
            </div>
        </div>
        <div style={{width:"310px"}} className='bg-body text-dark'>
            <div className='px-4 py-2' style={{display:"grid", gridTemplateColumns:"auto", justifyContent:"center"}}>
                <span className='fw-bold h4 mb-2'>{title}</span>
                <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap"}}>
                    {
                        badges.map((badge : string, key: number) => {
                            return <span className='badge bg-dark text-light m-1' key={key}>{badge}</span>                        
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default BoxWithOverlay