import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound() {
  const nav = useNavigate()
  return (
    <div className='bg-dark vh-100 d-flex justify-content-center text-center' style={{paddingTop:"69.16px"}}>
      <div className='container text-white'>
        <div className='row' style={{height:"40px"}}></div>
        <div className='' style={{display:"grid", gridTemplateColumns: "auto", justifyContent:"center"}}>
          <span className='h3'>Page Not Found</span>
          <button style={{margin:"auto"}} onClick={() => nav("/Home")} className='btn w-50 mt-3 btn-primary'>Home</button>
        </div>
        <div className='row' style={{height:"40px"}}></div>
      </div>
    </div>
  )
}

export default NotFound