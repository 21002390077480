import React from 'react'
import '../App.css';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
    children: React.ReactNode;
}

function PortfolioLink({children}: Props) {
    const location = useLocation();
    const nav = useNavigate();
    var { pathname } = location;
    if (pathname == "/") pathname = "/Home";
    
    const redirectPath = children?.toString().replaceAll(/ |\//gi,"");
    const normalizedPath = pathname.replaceAll(/ |\//gi,"");

    return (
        <div className='portfolio-link' onClick={() => {
            console.log(`redirecting to /${redirectPath}...`);
            nav("/" + redirectPath);
        }}>
            <span style={{fontSize:"1em"}} className={`${normalizedPath == redirectPath ? "text-success":"text-secondary"}`}>{children}</span>
        </div>
    )
}

export default PortfolioLink