export const pfp = require("./linkedin.PNG");
export const canadaFlag = require("./canada-flag.png");
export const itchIcon = require("./itchio-icon.png");
export const testOverlayImg = require("./gridoverlaytest.png");

// education section
export const cps803img = require('./cps803img.png');
export const cps721img = require('./cps721img.png');
export const cps510img = require('./cps510img.png');
export const cps633img = require('./cps633img.png');
export const cps393img = require('./cps393img.png');
export const cps590img = require('./cps590img.png');

// projects section
export const shorturlsimg = require('./shorturlsimg.png');
export const emailtrackimg = require('./emailtrackimg.png');
export const deathmatchimg = require('./deathmatchimg.png');
export const ecoquestimg = require('./ecoquestimg.png');
export const bugdatabaseimg = require('./bugdatabaseimg.png');

// resume section
export const resumeImg = require("./resume.png");
export const resumePdf = require("./resumepdf.pdf");