import React, { useState } from 'react'

interface formContent {
  name: string,
  email: string,
  message: string
}

function Contact() {
  const [formData,setFormData] = useState<formContent>({
    name: "",
    email: "",
    message: ""
  });

  const [isLoading,setIsLoading] = useState<boolean>(false)
  const [responseMessage,setResponseMessage] = useState<string>("")
  const [isError,setIsError] = useState<boolean>(false)

  const handleInput = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    
    const tempData : any = {...formData}
    tempData[e.target.name] = e.target.value;

    setFormData(tempData)
  }

  const handleSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)
    setIsError(false)
    setResponseMessage("")
    
    console.log(process.env.REACT_APP_CONTACT_FORM_ENDPOINT)
    var res = await fetch(process.env.REACT_APP_CONTACT_FORM_ENDPOINT! + "?" + `name=${formData.name}&email=${formData.email}&message=${formData.message}`, {
      method: "POST",
    });

    if (res.status == 200){
      setResponseMessage("Message sent successfully!")
    }
    else{
      setIsError(true)
      if (res.status == 400){
        var resjson = await res.json()
        console.log(resjson['message'])
        setResponseMessage(resjson['message'])
      }
      else if (res.status == 500){
        setResponseMessage("Something went wrong...")
      }
    }

    setIsLoading(false)
  }

  return (
        <div className='bg-dark vh-100 d-flex justify-content-center text-center' style={{paddingTop:"69.16px"}}>
          <div className='container text-secondary'>
            <div className='row' style={{height:"40px"}}></div>
            <div className='' style={{display:"grid", gridTemplateColumns: "auto"}}>
              <span className='h3'>Contact</span>
              <span className='lead fs-5'>You can connect with me on <a className='link-info' href='https://www.linkedin.com/in/danyalakhtar/'>LinkedIn</a>, send me an <a className='link-info' href='mailto:danyal.akhtar@torontomu.ca'>email</a>, or you can fill the form below:</span>
            </div>
            <div className='row' style={{height:"40px"}}></div>
            <form onSubmit={(e) => handleSubmit(e)} className='border form p-sm-4 p-3 p-md-5 shadow-lg' style={{borderRadius:"5px", width:"70%", margin:"auto", backgroundColor:"#6C7A89"}}>
              <h3 className='mt-2 mb-5'>Get In Touch</h3>
              <div className='contact-form-name-and-email-fields'>
                <input required onChange={(e) => handleInput(e)} name="name" value={formData.name} placeholder='Name' className='form-control'/>
                <input required type="email" onChange={(e) => handleInput(e)} name="email" value={formData.email} placeholder='Email' className='form-control'/>
              </div>
              <textarea required onChange={(e) => handleInput(e)} name="message" value={formData.message} placeholder='Enter your message' className="form-control mt-4" rows={3}></textarea>
              <button disabled={isLoading} type='submit' className='mt-3 form-control btn btn-primary fw-bold mb-3'>{isLoading ? "Sending...":"Submit"}</button>
              {responseMessage == "" ? <></>:<p className={`fw-bold text-${isError ? "danger":"success"}`}>{responseMessage}</p>}
            </form>
          </div>
        </div>
      )
}

export default Contact