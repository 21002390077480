import React from 'react'
import BoxWithOverlay from '../components/BoxWithOverlay'
import { canadaFlag, cps393img, cps510img, cps590img, cps633img, cps721img, cps803img, pfp, testOverlayImg } from '../images/_index'

function Education() {
    return (
        <div className='bg-dark vh-100 d-flex justify-content-center text-center' style={{paddingTop:"69.16px"}}>
          <div className='container'>
            <div className='row' style={{height:"40px"}}></div>
            <div className='' style={{display:"grid", gridTemplateColumns: "auto"}}>
              <span className='h3 text-primary'>Notable Courses</span>
              <span className='lead fs-5 text-secondary'>An overview of notable completed courses and ongoing courses, giving an idea of skills I have or will obtain</span>
            </div>
            <div className='row' style={{height:"40px"}}></div>
            <div className='overlay-box-grid pb-5'>
                <BoxWithOverlay 
                    hoverColor='#18123b'
                    image={cps803img} 
                    badges={["Scikit-Learn", "Tensorflow", "Python"]} 
                    title='CPS803'
                    description={
                        <div className='text-info'>
                            <span className='lead fs-5'>CPS803 - Machine Learning</span>
                            <p className='mt-2'>A study of algorithms capable of learning from data or prior experience, along with practical experience.</p>
                            <p><span className='mt-5 fw-bold'>Ends: </span>December 2024</p>
                        </div>
                    }
                />
                <BoxWithOverlay 
                    hoverColor='#12153b'
                    image={cps721img} 
                    badges={["Prolog", "Parsing", "Bayes Networks"]} 
                    title='CPS721' 
                    description={
                        <div className='text-info'>
                            <span className='lead fs-5'>CPS721 - Artifical Intelligence I</span>
                            <p className='mt-2'>An overview of fundamental topics that underly several areas of modern AI, and related techniques for tasks such as searching.</p>
                            <p><span className='mt-5 fw-bold'>Ends: </span>December 2024</p>
                        </div>
                    }
                />
                <BoxWithOverlay
                    hoverColor='#3b3512'
                    image={cps510img} 
                    badges={["Java", "Databases", "SQL"]} 
                    title='CPS510' 
                    description={
                        <div className='text-info'>
                            <span className='lead fs-5'>CPS510 - Database Systems I</span>
                            <p className='mt-2'>A study of advanced file management techniques involving fundamentals of database organization, design, and management.</p>
                            <p><span className='mt-5 fw-bold'>Ends: </span>December 2024</p>
                        </div>
                    }
                />
                <BoxWithOverlay
                    hoverColor='#12313b'
                    image={cps633img} 
                    badges={["C", "Network Security"]} 
                    title='CPS633' 
                    description={
                        <div className='text-info'>
                            <span className='lead fs-5'>CPS633 - Computer Security</span>
                            <p className='mt-2'>A course going over popular encryption algorithms, authentication technologies, and security concepts.</p>
                            <p><span className='mt-4 fw-bold'>Ends: </span>December 2024</p>
                        </div>
                    }
                />
                <BoxWithOverlay
                    hoverColor='#2c4554'
                    image={cps393img} 
                    badges={["Linux", "Bash", "C"]} 
                    title='CPS393' 
                    description={
                        <div className='text-info'>
                            <span className='lead fs-5'>CPS393 - Intro To Unix And C</span>
                            <p className='mt-2'>An introduction to Linux and C.</p>
                            <p><span className='mt-5 fw-bold'>Completed: </span>June 2023</p>
                        </div>
                    }
                />
                <BoxWithOverlay
                    hoverColor='#4d4946'
                    image={cps590img} 
                    badges={["Linux", "C", "Operating Systems"]} 
                    title='CPS590' 
                    description={
                        <div className='text-info'>
                            <span className='lead fs-5'>CPS590 - Operating Systems I</span>
                            <p className='mt-2'>An overview of operations systems and how they work.</p>
                            <p><span className='mt-5 fw-bold'>Completed: </span>April 2024</p>
                        </div>
                    }
                />
            </div>
          </div>
        </div>
      )
}

export default Education