import React from 'react'
import { pfp, canadaFlag, itchIcon } from '../images/_index'
import "../App.css";
import { handleVisit } from '../helpers/handleVisit';
import { useNavigate } from 'react-router-dom';

function Home() {
    const nav = useNavigate()
  return (
    <div className='bg-dark vh-100 d-flex justify-content-center mb-5' style={{overflowX:"hidden", overflowY:"hidden",paddingTop:"69.16px"}}>
        <div className='text-secondary' style={{overflowX:"hidden"}}> 
            <div className='about-me-header-section'>
                <div className='p-3'>
                    <img className='img-responsive border' src={pfp} width="220em" height="220em" style={{borderRadius:"50%"}} />
                </div>
                <div className='p-3'>
                    <h3 className='text-secondary'>Hi, my name is:</h3>
                    <div className='text-primary lead fs-1'>Danyal Akhtar</div>
                    <p className='p mt-4 text-secondary'>
                        I am a TMU third year CS student. I
                        specialize in full stack web development,
                        I like to learn new skills, so while my
                        speciality is full stack, I'm open to learning 
                        skills such as mobile development and
                        game development, and anything else
                    </p>
                    <button onClick={() => nav("/Resume")} className='btn btn-primary'>View Resume</button>
                </div>
            </div>
            <div className='row' style={{height:"40px"}}></div>
            <div className='w-100 text-center' style={{display:"grid", gridTemplateColumns:"auto", margin:"auto"}}>
                <span className='w-75 lead fs-2' style={{margin:"auto"}}>Skills Overview</span>
                <span className='w-75 fs-6 mt-3' style={{margin:"auto"}}>Below is a quick summary of my skills. If you want to find out more about my experience, you can view my:</span>
                <button onClick={() => nav("/Projects")} className='text-dark mt-4 fs-6 btn btn-sm btn-info' style={{margin:"auto", width:"200px"}}><span className='lead'>Projects</span></button>
                <button onClick={() => nav("/Education")} className='text-dark mt-1 fs-6 btn btn-sm btn-info' style={{margin:"auto", width:"200px"}}><span className='lead'>Education</span></button>
            </div>
            <div className='row' style={{height:"40px"}}></div>
            <div className=''>
                <div className='about-me-skills-grid text-dark justify-content-center'>
                    <div className='shadow-glow text-center pt-3' style={{width:"18em", height: "16em", borderRadius:"5px"}}>
                        <span className='fw-bold fs-4'>Frontend</span>
                        <ul className='text-start mx-2 mt-3 skills-list' style={{listStyle:'none'}}>
                            <li>HTML/CSS</li>
                            <li>Javascript</li>
                            <li>Typescript</li>
                            <li>React</li>
                            <li>Bootstrap</li>
                            <li>Tailwind CSS</li>
                        </ul>
                    </div>
                    <div className='shadow-glow text-center pt-3' style={{width:"18em", height: "16em", borderRadius:"5px"}}>
                        <span className='fw-bold fs-4'>Backend</span>
                        <ul className='text-start mx-2 mt-3 skills-list' style={{listStyle:'none'}}>
                            <li>ASP.NET</li>
                            <li>Nodejs</li>
                            <li>RabbitMQ</li>
                            <li>Microsoft SQL Server</li>
                            <li>MySQL</li>
                            <li>MongoDB</li>
                        </ul>
                    </div>
                    <div className='shadow-glow text-center pt-3' style={{width:"18em", height: "16em", borderRadius:"5px"}}>
                        <span className='fw-bold fs-4'>Others</span>
                        <ul className='text-start mx-2 mt-3 skills-list' style={{listStyle:'none'}}>
                            <li>Docker</li>
                            <li>Kubernetes</li>
                            <li>Nginx</li>
                            <li>Project Management - Scrum</li>
                            <li>Cloud Computing (Azure)</li>
                            <li>Domain Management</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='row' style={{height:"200px"}}></div>
        </div>
    </div>
  )
}

export default Home