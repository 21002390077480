import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Contact, Education, Home, NotFound, Projects, Resume } from './pages/_index';
import Links from './components/Links';
import { Header } from './layout/_index';

function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Home" element={<Home/>}/>
        <Route path="/Education" element={<Education/>}/>
        <Route path="/Projects" element={<Projects/>}/>
        <Route path="/Contact" element={<Contact/>}/>
        <Route path="/Resume" element={<Resume/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  );
}

export default App;
