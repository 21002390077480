import React from 'react'
import { itchIcon } from '../images/_index'
import PortfolioLink from '../components/PortfolioLink'
import { handleVisit } from '../helpers/handleVisit'

function Header() {
    return (
        <nav className="navbar navbar-expand navbar-dark w-100 px-3" style={{position:"fixed",backgroundColor:"#6C7A89", zIndex:10}}>
            <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <a onMouseDown={(e) => handleVisit(e, "https://github.com/d3akhtar")} className="nav-link fs-4 mx-1" style={{cursor:"pointer"}}><span className='text-center text-primary external-link-icon'><i className="bi bi-github"></i></span></a>
                    </li>
                    <li className="nav-item">
                        <a onMouseDown={(e) => handleVisit(e, "https://www.linkedin.com/in/danyalakhtar/")} className="nav-link fs-4 mx-1" style={{cursor:"pointer"}}><span className='text-center text-primary external-link-icon'><i className="bi bi-linkedin"></i></span></a>
                    </li>
                    <li className="nav-item">
                        <a onMouseDown={(e) => handleVisit(e, "https://sunstation.itch.io/")} className="nav-link fs-4 mx-1" style={{cursor:"pointer"}}><span className='text-center portfolio-link-image external-link-icon'><img width={35} height={35} src={itchIcon}/></span></a>
                    </li>
                </ul>
                <ul className='navbar-nav ml-auto'>
                    <li className="nav-item laptop-nav-item">
                        <a className="nav-link" style={{cursor:"pointer"}}><span className='text-center'><PortfolioLink>Home</PortfolioLink></span></a>
                    </li>
                    <li className="nav-item laptop-nav-item">
                        <a className="nav-link" style={{cursor:"pointer"}}><span className='text-center'><PortfolioLink>Education</PortfolioLink></span></a>
                    </li>
                    <li className="nav-item laptop-nav-item">
                        <a className="nav-link" style={{cursor:"pointer"}}><span className='text-center'><PortfolioLink>Projects</PortfolioLink></span></a>
                    </li>
                    <li className="nav-item laptop-nav-item">
                        <a className="nav-link" style={{cursor:"pointer"}}><span className='text-center'><PortfolioLink>Resume</PortfolioLink></span></a>
                    </li>
                    <li className="nav-item laptop-nav-item">
                        <a className="nav-link" style={{cursor:"pointer"}}><span className='text-center'><PortfolioLink>Contact</PortfolioLink></span></a>
                    </li>
                    <li className="nav-item dropdown dropright sections-dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="bi bi-list"></i>
                        </a>
                        <div className="dropdown-menu text-end lead fs-3 bg-secondary border" aria-labelledby="navbarDropdown" style={{marginRight:"100px"}}>
                            <a className="nav-link" style={{cursor:"pointer"}}><span className=''><PortfolioLink>Home</PortfolioLink></span></a>
                            <a className="nav-link" style={{cursor:"pointer"}}><span className=''><PortfolioLink>Education</PortfolioLink></span></a>
                            <a className="nav-link" style={{cursor:"pointer"}}><span className=''><PortfolioLink>Projects</PortfolioLink></span></a>
                            <a className="nav-link" style={{cursor:"pointer"}}><span className=''><PortfolioLink>Resume</PortfolioLink></span></a>
                            <a className="nav-link" style={{cursor:"pointer"}}><span className=''><PortfolioLink>Contact</PortfolioLink></span></a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Header