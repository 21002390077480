import React from 'react'
import BoxWithOverlay from '../components/BoxWithOverlay'
import { bugdatabaseimg, cps590img, deathmatchimg, ecoquestimg, emailtrackimg, shorturlsimg, testOverlayImg } from '../images/_index'

function Projects() {
  return (
    <div className='bg-dark vh-100 d-flex justify-content-center text-center mb-5' style={{paddingTop:"69.16px"}}>
      <div className='container text-white'>
        <div className='row' style={{height:"40px"}}></div>
        <div className='' style={{display:"grid", gridTemplateColumns: "auto"}}>
          <span className='h3'>Projects</span>
          <span className='lead fs-5'>An overview of skills I've practiced and what I can offer. <span className='fw-bold text-success'>Click</span> on the boxes to visit the project GitHub (or itch.io) for more details.</span>
        </div>
        <div className='row' style={{height:"40px"}}></div>
        <div className='overlay-box-grid pb-5'>
          <BoxWithOverlay
              projectLink='https://github.com/d3akhtar/ShortUrls'
              hoverColor='#5c4905'
              image={shorturlsimg} 
              badges={["ASP.Net", "Docker", "React Typescript", "RabbitMQ", "Microsoft SQL"]} 
              title='ShortUrls' 
              description={
                  <div className='text-info'>
                      <span className='lead fs-5'>ShortUrls</span>
                      <p className='mt-2'>A fullstack web application that takes long urls and shortens them. It implements a microservices style architecture.</p>
                      <p><span className='mt-5 fw-bold'>Worked On: </span>May 2024 - July 2024</p>
                  </div>
              }
          />
          <BoxWithOverlay
              projectLink='https://github.com/d3akhtar/EmailTrack'
              hoverColor='#5c2f05'
              image={emailtrackimg} 
              badges={["ASP.Net", "Docker", "React Typescript", "OAuth2", "Google API", "MySQL"]} 
              title='EmailTrack' 
              description={
                  <div className='text-info'>
                      <span className='lead fs-5'>EmailTrack</span>
                      <p className='mt-2'>A fullstack web application that gets various stats from your Gmail metadata from the last two months and displays them with graphs.</p>
                      <p><span className='mt-5 fw-bold'>Worked On: </span>July 2024 - Aug 2024</p>
                  </div>
              }
          />
          <BoxWithOverlay
              projectLink='https://sunstation.itch.io/death-match'
              hoverColor='#61604b'
              image={deathmatchimg} 
              badges={["Unity", "C#", "Multiplayer", "Game Development"]} 
              title='Death Match' 
              description={
                  <div className='text-info'>
                      <span className='lead fs-5'>Death Match</span>
                      <p className='mt-2'>A multiplayer game where players match cards to attack their friends and be the last person standing.</p>
                      <p><span className='mt-5 fw-bold'>Worked On: </span>Jan 2024 - June 2024</p>
                  </div>
              }
          />
          <BoxWithOverlay
              projectLink='https://github.com/Sabagan/EcoQuest'
              hoverColor='#004017'
              image={ecoquestimg} 
              badges={["Flutter", "Dart"]} 
              title='EcoQuest' 
              description={
                  <div className='text-info'>
                      <span className='lead fs-5'>EcoQuest</span>
                      <p className='mt-2'>A mobile application for the 36-Hour TerraHacks hackathon that assigns users quests to help them contribute to saving the environment.</p>
                      <p><span className='mt-5 fw-bold'>Worked On: </span>Aug 2024</p>
                  </div>
              }
          />
          <BoxWithOverlay
              projectLink='https://github.com/d3akhtar/Bug-Database'
              hoverColor='#010536'
              image={bugdatabaseimg} 
              badges={["Nodejs", "jQuery", "MySQL"]} 
              title='Bug Database' 
              description={
                  <div className='text-info'>
                      <span className='lead fs-5'>Bug Database</span>
                      <p className='mt-2'>A bug report system for users to log bugs and errors into a database and keep track of them.</p>
                      <p><span className='mt-5 fw-bold'>Worked On: </span>March 2024 - April 2024</p>
                  </div>
              }
          />
        </div>
      </div>
    </div>
  )
}

export default Projects