import React from 'react'
import { resumeImg, resumePdf } from '../images/_index'

function Resume() {
  return (
    <div className='bg-dark vh-100 d-flex justify-content-center text-center' style={{paddingTop:"69.16px"}}>
      <div className='container text-white text-start'>
        <div className='row' style={{height:"40px"}}></div>
        <div className='' style={{display:"grid", gridTemplateColumns: "auto", justifyContent:"center"}}>
          <div className='d-flex justify-content-between align-items-center'>
            <span className='h3'>Resume</span>
            <a href={resumePdf} download={"Danyal Akhtar - Resume"} className='btn btn-primary my-2'><i className="bi bi-download"></i> Download PDF</a>
          </div>
          <img className='resume-img' src={resumeImg}></img>
        </div>
        <div className='row' style={{height:"40px"}}></div>
      </div>
    </div>
  )
}

export default Resume